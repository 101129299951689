import React from 'react'
import "../../../scss/layout/header/footer.scss"
import Logo from "../../../images/footer/Logo.png"
import Facebook from "../../../images/footer/Facebook.png"
import Twitter from "../../../images/footer/Twitter.png"
import CompLogo from "../../../images/footer/OtherLogo.png"

export default function Footer() {
  return (
    <div className="footer">
    <div className="mars-style">
      <div className="upper noto-san">
        <div className="column-container ">
          <div className="column">
            <ul>
              <li>Webマーケティング</li>
              <li>システム開発</li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>当社の強み</li>
              <li>企業情報</li>
              <li>実績紹介</li>
            </ul>
          </div>
        </div>
        <div className="column-container">
          <div className="column">
            <ul>
              <li>お問い合わせ</li>
              <li>採用情報</li>
              <li>パートナー募集</li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>お知らせ</li>
              <li>チャネル</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lower noto-san">
        <div className="comp-logo">
            <div className="left-part">
              <div className="img-container">
              <img src={Logo}/>
              </div>
              <p className="noto-san">株式会社 アリスタイル</p>
            </div>
            <div className="right-part">
            <a href='https://www.facebook.com/alistyle.inc/' className="icon">
              <img src={Facebook} alt=''/>
            </a>
            <a href="https://twitter.com/alistyle_inc" className="icon">
              <img src={Twitter} alt='' />
            </a>
            </div>
        </div>
        <div className="copyright">
            <div className="left">
              <p className="noto-san">Copyright ©Alistyle All Rights Reserved.</p>
            </div>
            <div className="right">
              <div className="details">
              <p className="noto-san">情報セキュリティ基本方針</p>
              <p className="noto-san">プライバシーポリシー</p>
              </div>
              <div className="logo">
                <img src={CompLogo} />
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}
