import React, {useEffect} from "react";
import Header from "../components/layout/header/Header";
import "../scss/landingpage.scss"

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css"
import 'swiper/css/autoplay'
import 'swiper/css/bundle'

import SwiperImage1 from "../images/section2/image1.png"
import SwiperImage2 from "../images/section2/image2.png"
import SwiperImage3 from "../images/section2/image3.png"
import SwiperImage4 from "../images/section2/image4.png"
import SwiperImage5 from "../images/section2/image5.png"
import SwiperImage6 from "../images/section2/image6.png"

import ReviewCard from "../components/ReviewCard"

import BrandCard from "../components/BrandCard"
import BrandImage1 from "../images/branchcard/dxsupporter.png"
import BrandImage2 from "../images/branchcard/mastyle.png"

import BgImage from "../images/section5/image1.png"
import TextImage from "../images/section5/text.png"
import NumberCard from "../components/NumberCard";

import ImgText from "../images/section6/Intersection 10.png"
import NoticeCard from "../components/NoticeCard"

import Image1 from "../images/noticecard/image1.png"
import Image2 from "../images/noticecard/image2.png"
import Image3 from "../images/noticecard/image3.png"
import Image4 from "../images/noticecard/image4.png"

import Button from "../components/Button";
import Arrow from "../images/button/arrow-red.png"
import CircleArrow from "../images/section8/circlearrow.png"

import { Autoplay } from "swiper/modules"
import SwiperCore from "swiper"

import AchieveCard from "../components/AchieveCard"
import AchieveCardSmall from "../components/AchieveCardSmall";
import AchieveImage from "../images/achievecard/image1.png"
import AchieveCardSmallImage from "../images/achievecard/image2.png";
import AchieveCardSmallImage2 from "../images/achievecard/image3.png";

import SimpleButton from "../components/SimpleButton";

import BlockCard from "../components/BlockCard";
import BlockImage1 from "../images/blockcard/image1.png"
import BlockImage2 from "../images/blockcard/image2.png"

import MessageIcon from "../images/section8/message.png"
import PhoneIcon from "../images/section8/phone.png"
import ArrowUp from "../images/pagetop/arrowup.png"

import CookiesModal from "../components/Cookies";

import AOS from "aos";
import "aos/dist/aos.css";
import AdsComponent from "../components/AdsComponent";
import Footer from "../components/layout/header/Footer";

export default function LandingPage() {

  useEffect(() => {
    AOS.init({
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      y: 50,
      throttleDelay: 99,

      offset: 120,
      easing: "ease",
      once: false,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  useEffect(() => {
    const sections = [...document.querySelectorAll(".secbg")];
    document.body.style.background = sections[0].getAttribute("data-bg");
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;

    const sections = [...document.querySelectorAll(".secbg")];
    const visibleSection = sections
      .map((section) => {
        const rect = section.getBoundingClientRect();
        return { section, rect };
      })
      .find((section) => section.rect.bottom >= windowHeight * 0.5);

    if (visibleSection && visibleSection.section) {
      console.log(visibleSection.section.id);
      if (visibleSection.section.id === "section6") {
        document.body.style.background = "white";
        document.body.style.color = "black";
      } else if (visibleSection.section.id === "section5") {
        document.body.style.background = "#374453";
        document.body.style.color = "white";
      }
    }
  };

  SwiperCore.use([Autoplay])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Header />

      <div className="section1">
        <div className="container">
        </div>
        <div className="title-container">
          <div className="h1-container">
            <h1 className="noto-san" id="first-title">「やりたい」を「できる」に </h1>
            <h1 className="noto-san" id="second-title"> 今こそビジネスに変革を。</h1>
          </div>
          <div className="h3-container">
            <h3>ビジネスの流れを変えるDX。</h3>
            <h3>業務効率化、企業戦略をデジタルでスマートに</h3>
          </div>
        </div>
      </div>

      <div className="section2">
        <div className="swiper-container">
          <Swiper
            spaceBetween={15}
            slidesPerView={1.3}
            centeredSlides={true}
            breakpoints={{
              560: {
                slidesPerView: 2,
                spaceBetween: 20,

              },
              960: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            loop={true}
            enabled={true}
            speed={3000}
            autoplay={{
              delay: 300,
              disableOnInteraction: false
            }}
          >
            <SwiperSlide><img src={SwiperImage1} alt="" /></SwiperSlide>
            <SwiperSlide><img src={SwiperImage2} alt="" /></SwiperSlide>
            <SwiperSlide><img src={SwiperImage3} alt="" /></SwiperSlide>
            <SwiperSlide><img src={SwiperImage4} alt="" /></SwiperSlide>
            <SwiperSlide><img src={SwiperImage5} alt="" /></SwiperSlide>
            <SwiperSlide><img src={SwiperImage6} alt="" /></SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="section3">
        <div className="mars-style">
          <div className="title-container" data-aos="fade-up" data-aos-delay="700">
            <div className="english-title noto-san">
              <p>Digital Transformation</p>
            </div>
            <div className="large-text"  data-aos="fade-up" data-aos-delay="700">
              <h3>中小企業の抱える様々な問題を<br className="br-pc"></br>
                デジタルトランス<br className="br-sp"></br>フォーメーション（DX）推進で
                改良改善を繰り返していきます。</h3>
            </div>
            <div className="small-text"  data-aos="fade-up" data-aos-delay="800">
              <h4>私たちは中小企業専門のDXコンサルティング・DXサポーターです。</h4>
              <h4>商社・卸売業、医療・医学学会のDX推進を支援することを得意としています。</h4>
            </div>
          </div>
          <div className="reaction-container"  data-aos="fade-up" data-aos-delay="900">
            <div className="reaction-title">
              <h3>こんなお悩みご相談ください</h3>
            </div>
            <div className="reaction-emoji">
              <div className="first-group">
                <ReviewCard
                  content="社内に専任がいない、<br>
                新たに採用できない"
                />
                <ReviewCard
                  content="費用対効果がわからず<br>
                コストがかけられない"
                />
                <ReviewCard
                  content="具体的になにを改善すればよいか<br>
                わからない"
                />
              </div>
              <div className="second-group">
                <ReviewCard
                  content="DX化の進め方がわからない"
                />
                <ReviewCard
                  content="業務が楽になるツールを<br>
                コストかけずに使いたい"
                />
                <ReviewCard
                  content="業務改善のためツール導入したが<br>
                使いこなせていない"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section4">
        <div className="mars-style">
          <div className="brand-container">
            <BrandCard
              subtitle="中小企業DXコンサルティング"
              title="DX SUPPORTER"
              content="デジタル活用により業績向上・業務改善を目指している中小企業を対象にデジタルトランスフォーメーション（DX）推進のお手伝いをしています。"
              weblink="https://dx.alistyle.jp/"
              imageUrl={BrandImage1}
            />
            <BrandCard
              subtitle="中小企業向けマーケティングツール"
              title="MAStyle"
              content="見込み客が見えてくるから効率的に営業できる。
               費用を抑えたちょうどよいマーケティングツールで、BtoBビジネスにプラスαを。"
              weblink="https://mastyle.alistyle.jp/"
              imageUrl={BrandImage2}
            />
          </div>
        </div>
      </div>

      <div className="section5 secbg" id="section5">
        <div className="bg-container">
        </div>
        <div className="img-container">
          <img src={BgImage} alt="" />
        </div>
        <div className="text-container">
          <img src={TextImage} />
        </div>
        <div className="form-container">
          <div className="mars-style">
            <div className="title-container">
              <h3 className="title-sect5">DX<br></br>推進サポート</h3>
              <p className="sect-5-p">私たちは商社・卸売業、医療・医学学会のお客様とのお取引を多くさせていただいております。</p>
              <p className="sect-5-p">業界に対する数々の知見から業種に対する最適なDX推進のご提案、</p>
              <p className="sect-5-p">事業理解・理念・目標などの事業計画を理解し、最適化されたサポートをご提供いたします。</p>
            </div>

            <div className="card-container">
              <NumberCard
                number="01"
                subtitle="System Development"
                title="システム開発による業務改善・業務効率化"
                content="顧客管理、販売管理システムの開発、ECの基幹システム連携、セキュリティ対策、クラウド構築など
              業務改善・効率化の仕組みを構築させていただきます。"
                textboxes={[
                  { text: "システム開発（企画、開発、運用保守）" },
                ]}
                label="システム開発を見る"
              />
              <NumberCard
                number="02"
                subtitle="Web Marketing"
                title="Webマーケティングによる集客・販促支援"
                content="集客力の向上、認知度の向上、販売強化、採用力強化、IR情報の発信、CMS構築など、
              課題に合わせて最適な提案をさせていただきます。"
                textboxes={[
                  { text: "ホームページ制作（企画、戦略策定、作成）" },
                  { text: "ホームページ運用サポート" },
                  { text: "サーバー構築" },
                  { text: "グラフィックデザイン" },
                  { text: "ホスティング（ドメイン取得管理、レンタルサーバー）" },
                ]}
                label="Webマーケティングを見る"
              />
              <NumberCard
                number="03"
                subtitle="System Development"
                title="システム開発による業務改善・業務効率化"
                content="顧客管理、販売管理システムの開発、ECの基幹システム連携、セキュリティ対策、クラウド構築など
              業務改善・効率化の仕組みを構築させていただきます。"
                textboxes={[
                  { text: "ホームページ制作（企画、戦略策定、作成）" },
                  { text: "ホームページ運用サポート" },
                  { text: "サーバー構築" },
                  { text: "グラフィックデザイン" },
                  { text: "ホスティング（ドメイン取得管理、レンタルサーバー）" },
                ]}
                tag={"tag"}

              />
            </div>
          </div>
        </div>
      </div>

      <div className="section6 secbg" id="section6">
        <div className="mars-style">
          <div className="img-container">
            <img src={ImgText} alt="" />
          </div>
          <div className="upper-part">
            <div className="title-container">
              <h3>実績紹介</h3>
              <p>担当させていただいたホームページ制作やシステム開発の事例を紹介しています。</p>
            </div>
            <div className="card-container">
              <NoticeCard
                image={Image1}
                text="テキストテキスト"
                content="タイトルがはいりますタイトルがはいりますタイトルがはいります"
              />
              <NoticeCard
                image={Image2}
                text="テキストテキスト"
                content="タイトルがはいりますタイトルがはいりますタイトルがはいります"
              />
              <NoticeCard
                image={Image3}
                text="テキストテキスト"
                content="タイトルがはいりますタイトルがはいりますタイトルがはいります"
              />
              <NoticeCard
                image={Image4}
                text="テキストテキスト"
                content="タイトルがはいりますタイトルがはいりますタイトルがはいります"
              />
            </div>
            <div className="button-container">
              <Button
                label="パートナー応募はこちら"
                style="btn3"
                icon={Arrow}
              />
            </div>
          </div>
          <div className="mid-part">
            <div className="channel-section">
              <div className="button-container noto-san">
                <h3>Channel</h3>
                <div className="button-sp">
                  <SimpleButton
                    label="一覧を見る"
                  />
                </div>
              </div>
              <div className="card-container">
                <AchieveCard
                  image={AchieveImage}
                  content="タイトルがはいりますタイトルがはいりますタイトルがはいりますタイトルがはいります"
                  date="2022.12.02"
                  text="テキストテキスト"
                />
                <AchieveCardSmall
                  image={AchieveCardSmallImage}
                  content="タイトルがはいりますタイトルがはいりますタイトルがはいりますタイトルがはいります"
                  date="2022.12.02"
                  text="テキストテキスト"
                />
                <AchieveCardSmall
                  image={AchieveCardSmallImage2}
                  content="タイトルがはいりますタイトルがはいりますタイトルがはいりますタイトルがはいります"
                  date="2022.12.02"
                  text="テキストテキスト"
                />
              </div>
              <div className="button-pc">
                <SimpleButton
                  label="一覧を見る"
                />
              </div>
            </div>
          </div>
          <div className="lower-part">
            <div className="lower-container">
              <div className="lower-title noto-san">
                <h3>お知らせ</h3>
                <div className="button-pc">
                  <SimpleButton
                    label="一覧を見る"
                  />
                </div>
              </div>
              <div className="lower-dates noto-san">
                <div className="dates">
                  <span>2022.12.02</span>
                  <p>冬季休業のお知らせ</p>
                </div>
                <div className="dates">
                  <span>2022.12.02</span>
                  <p>東京営業所移転のお知らせ</p>
                </div>
                <div className="dates">
                  <span>2022.12.02</span>
                  <p>在宅勤務（リモートワーク）実施のお知らせテキストがはいります</p>
                </div>
              </div>
              <div className="button-sp">
                <SimpleButton
                  label="一覧を見る"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section7">
        <div className="mars-style">
          <div className="card-container">
            <BlockCard
              image={BlockImage2}
              title="採用情報"
              content="一緒に会社を成長させていく仲間を募集しています。<br>
                当社の理念に共感いただける方、ぜひご応募ください。"
            />
            <BlockCard
              image={BlockImage1}
              title="パートナー募集"
              content="お互いの技術力、ノウハウを共有し、相互にビジネスチャンスを広め、共に成長していきませんか。"
            />
          </div>
        </div>
      </div>

      <div className="section8">
        <div className="container">
          <div className="contact noto-san">
            <span>Contact</span>
            <h3>DXに関するご相談、制作のお見積り依頼はこちらから</h3>
          </div>
          <div className="icon-container">
            <a href="" className="message-icon">
              <div className="icon noto-san">
                <img src={MessageIcon} />
              </div>
              <p>メールでのお問い合わせ</p>
            </a>
            <div className="phone-icon">
              <div className="icon">
                <img src={PhoneIcon} />
              </div>
              <div className="details">
                <p className="title">お電話でのお問い合わせ</p>
                <p className="number">011-231-6033</p>
                <p className="time">9:00~18:00（土日祝休み）</p>
              </div>
            </div>
          </div>
          <div className="other-contact">
            <div className="mars-style">
              <div className="contact-container noto-san">
                <div className="contact-title noto-san">
                  <h3>札幌本社</h3>
                </div>
                <div className="contact-details">
                  <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                  <p className="details">TEL：011-231-6033</p>
                  <div className="map-icon">
                    <p className="map">Google map</p>
                    <div className="icon">
                      <img src={CircleArrow} alt="arrow" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-container">
                <div className="contact-title noto-san">
                  <h3>札幌本社</h3>
                </div>
                <div className="contact-details noto-san">
                  <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                  <p className="details">TEL：011-231-6033</p>
                  <div className="map-icon">
                    <p className="map">Google map</p>
                    <div className="icon">
                      <img src={CircleArrow} alt="arrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-top">
        <button
          className="scroll-to-top-button noto-san"
          onClick={scrollToTop}
        >
          <div className="image-container">
            <img src={ArrowUp} />
          </div>
          <p>PAGE TOP</p>
        </button>
      </div>

      <div className="floating-ads">
          <AdsComponent />
      </div>

      <div className="cookies">
          <CookiesModal />
      </div>

      <div className="footer">
      <Footer/>
      </div>


    </>
  );
}
