import React from 'react'
import TextBox from './TextBox'
import "../scss/numbercard.scss"
import "../scss/textbox.scss"
import Button from './Button'
import Icon from "../images/button/arrow.png"

export default function NumberCard({ number, subtitle, title, content, text, textboxes, tag, label}) {
    return (
        <div className='card-containers'>
            <div className='number'>
                <span>{number}</span>
            </div>
            <div className='content-container'>
                <div className='title-container'>
                    <p className='subtitle'>{subtitle}</p>
                    <h3 className='title'>{title}</h3>
                    <p className='content'>{content}</p>
                </div>

                <div className='text-box-container'>
                    {textboxes.map((textbox, index) => (
                        <TextBox key={index} text={text} {...textbox} />
                    ))}
                </div>
                <div className={`button-container ${tag}`}>
                    <Button 
                    label={label}
                    style="btn2"
                    icon={Icon}
                    />
                </div>
            </div>
        </div>
    )
}
