import React from "react";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/header/Footer";
import ArrowUp from "../images/pagetop/arrowup.png"
import MessageIcon from "../images/section8/message.png"
import PhoneIcon from "../images/section8/phone.png"
import CircleArrow from "../images/section8/circlearrow.png"
import "../scss/partner.scss"
import Button from "../components/Button";
import icon from "../images/button/arrow-red.png"

import QImage from "../images/partner/Q.png"

const Partner = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleButtonClick = (event) => {
        const parentElem = event?.currentTarget.parentNode;
        const target = parentElem.querySelector(".drop_down");
    
        if (target) {
          !target.classList.contains("open")
            ? target.classList.add("open")
            : target.classList.remove("open");
        }
    }
    return (
        <div className="partner">
            <Header />

            <div className='section-legend'>
                <p>アリスタイルTOP &gt;</p>
            </div>

            <div className='section1'>
                <div className='background'></div>
                <div className='mars-style'>
                    <div className='title noto-san'>
                        <span>Partner</span>
                        <h3>パートナー募集</h3>
                    </div>
                </div>
            </div>

            <div className="twotext">
                <div className="mars-style">
                    <p className="ftext noto-san">
                        当社では制作パートナー様と協力し合うことで、よりお客様の事業の発展に貢献できると考えております。
                        <br />
                        制作パートナーシップを結び、お互いの技術力、ノウハウを共有し、相互にビジネスチャンスを広め、共に成長していきませんか。
                    </p>
                </div>
            </div>

            <div className="positionSec">
                <div className="mars-style">
                    <div className="positionSec--container">
                        <div className="underline--box">
                            <span className="red-underline"></span>
                        </div>
                        <h3 className="posiTxt noto-san">募集職種</h3>
                        <div className="box">
                            <p className="boxttle noto-san">現在募集している職種</p>
                            <p className="pdot ppdot noto-san">・フロントエンジニア（Web）</p>
                            <p className="pdot ppdot noto-san">
                                ※ 法人、個人、フリーランス 問いません。
                            </p>
                        </div>
                        <div className="texts">
                            <p className="txt noto-san">
                                当社が制作するウェブサイトは企業様のコーポレートサイト、サービスサイト、ブランドサイトが主になりますが、その他キャンペーン、プロモーション、ECサイト、業務系のWebシステム開発など、多種多様なプロジェクトに対応しています。
                                <br />
                                規模も1Pのコーディングから数千ページのサイトまで様々です。
                            </p>
                            <p className="txt noto-san">
                                制作パートナー会社様には対応可能なジャンルでご協力をお願いしており、ご経験、スキルの条件に当てはまれば個人の方の応募もお待ちしております。
                            </p>
                            <div className="div">
                                <p>※ </p>
                                <p className="txtt noto-san">
                                    お電話でのご連絡はご遠慮ください。まずはフォームからのご応募をお願いいたします。
                                </p>
                            </div>
                        </div>
                        <h2 className="hdrTtl noto-san">
                            フロントエンジニア（Web）パートナー条件
                        </h2>
                        <div className="box2">
                            <p className="bx2ttle noto-san">法人・個人 共通必須スキル</p>
                            <div className="div">
                                <p>・</p>
                                <p className="pdott noto-san">
                                    HTML/CSS3/JavaScriptのコーディング知識と実務経験
                                </p>
                            </div>
                            <div className="div">
                                <p>・</p>
                                <p className="pdott noto-san">
                                    デザインの再現に注力していただけること
                                </p>
                            </div>
                            <div className="div">
                                <p>・</p>
                                <p className="pdott noto-san">Gitでのバージョン管理</p>
                            </div>
                        </div>
                        <div className="box3">
                            <p className="bx2ttle2 noto-san">
                                個人の方は以下についてもご確認ください
                            </p>
                            <div className="div">
                                <p>・</p>
                                <p className="pdots noto-san">
                                    フリーランスとして3年以上の活動実績がある方
                                </p>
                            </div>
                            <div className="div">
                                <p>・</p>
                                <p className="pdots noto-san">
                                    架空ではないWebサイト構築の制作実績を複数件ご提示いただける方
                                </p>
                            </div>
                            <div className="div">
                                <p>・</p>
                                <p className="pdots noto-san">
                                    納期を守る方（突発的な事情で難しくなった場合でもすぐにその旨ご連絡いただける方）
                                </p>
                            </div>
                            <div className="div">
                                <p>・</p>
                                <p className="pdots noto-san">
                                    平日日中にメールや電話での連絡がつき、24時間以内に返信をいただける方。
                                </p>
                            </div>
                        </div>
                        <div className="div">
                            <p>※</p>
                            <p className="ppdots noto-san">
                                お電話でのご連絡はご遠慮ください。まずはフォームからのご応募をお願いいたします。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="applic">
                <div className="mars-style">
                    <div className="aplic--container">
                        <div className="underline--box">
                            <span className="red-underline"></span>
                        </div>
                        <h3 className="appliTxt noto-san">ご応募</h3>
                        <p className="info noto-san">
                            ご応募いただける場合は、以下のボタンからフォームにご入力ください。
                            <br />
                            弊社では、協力会社様と共にプロジェクトを進めるにあたり、協力会社様の情報セキュリティ管理体制に関する情報提供をお願いしております。
                            <br />
                            質問自体は5分ほどで登録できる内容となっておりますのでご協力お願いいたします。
                        </p>
                        <div className="button">
                            <Button
                                style={"btn3"}
                                label={"パートナー応募はこちら"}
                                icon={icon}
                            />
                        </div>
                        <p className="info infoo noto-san">
                            ご応募いただいた内容は社内で審査の上、パートナーとして協力していただきたい方に、お仕事が発生したタイミングでご連絡させていただきます。
                            <br />
                            大変申し訳ございませんが、現在全てのご応募には返信できないこと予めご了承ください。
                        </p>
                        <div className="divv">
                            <div className="div">
                                <p>※</p>
                                <p className="pdott noto-san">
                                    ご応募頂いた情報は、別の目的に利用したり、第三者に提供することはありません。
                                </p>
                            </div>
                            <div className="div">
                                <p>※</p>
                                <p className="pdott noto-san">
                                    正しい情報の記入をお願いいたします。万が一、情報に虚偽があった場合、お取引を停止させて頂く場合がございます。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="Question">
                <div className="mars-style">
                    <div className="que--container">
                        <div className="underline--box">
                            <span className="red-underline"></span>
                        </div>
                        <h3 className="queTxt noto-san">よくあるご質問</h3>

                        <div className="container">
                            {/* Q1 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            契約の取り交わしはありますか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down `}>
                                    <p className="ans noto-san">
                                        業務委託契約書および、機密保持契約の締結をお願いしています。
                                    </p>
                                </div>
                            </div>
                            {/* Q2 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            エリア関係無く応募可能ですか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down `}>
                                    <p className="ans noto-san">
                                        全国で募集していますのでエリアは関係ありません。基本的にはメール、口頭でのお打ち合わせが必要な場合は電話やGoogle
                                        Meetでコミュニケーションをとっています。
                                    </p>
                                </div>
                            </div>
                            {/* Q3 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            場合によっては逆にお仕事を請けてもらうこともできますか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down `}>
                                    <p className="ans noto-san">
                                        もちろん可能です。協力し合いながらお付き合いしたいと考えています。
                                    </p>
                                </div>
                            </div>
                            {/* Q4 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            お見積りの費用感はどの程度でしょうか。
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down`}>
                                    <p className="ans noto-san">
                                        プロジェクトにより変わりますので、その都度ご相談をお願いします。{" "}
                                    </p>
                                </div>
                            </div>
                            {/* Q5 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            支払条件はどうなっていますか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down`}>
                                    <p className="ans noto-san">
                                        プロジェクト完了月の月末締め請求の翌月末日支払でお願いしております。
                                    </p>
                                </div>
                            </div>
                            {/* Q6 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            制作ガイドラインはありますか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down `}>
                                    <p className="ans noto-san">
                                        基本的には弊社で定めたコーディングガイドラインに沿ってご対応いただきます。
                                    </p>
                                </div>
                            </div>
                            {/* Q7 */}
                            <div className="accordion--container">
                                <div class="drop_btn" onClick={handleButtonClick}>
                                    <div className="ptext">
                                        <div className="qbox">
                                            <img src={QImage} alt="QImage" />
                                        </div>
                                        <p className="ques noto-san">
                                            アリスタイルと直接会うことはできますか？
                                        </p>
                                    </div>
                                </div>
                                <div class={`drop_down `}>
                                    <p className="ans noto-san">
                                        北海道 札幌と東京
                                        新橋にオフィスがあります。担当者が不在の場合もございますので、来社の際は事前にご連絡いただけると幸いです。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="section8">
                <div className="container">
                    <div className="contact noto-san">
                        <span>Contact</span>
                        <h3>DXに関するご相談、制作のお見積り依頼はこちらから</h3>
                    </div>
                    <div className="icon-container">
                        <div className="message-icon">
                            <div className="icon-container noto-san">
                                <img src={MessageIcon} />
                            </div>
                            <p>メールでのお問い合わせ</p>
                        </div>
                        <div className="phone-icon">
                            <div className="icon">
                                <img src={PhoneIcon} />
                            </div>
                            <div className="details">
                                <p className="title">お電話でのお問い合わせ</p>
                                <p className="number">011-231-6033</p>
                                <p className="time">9:00~18:00（土日祝休み）</p>
                            </div>
                        </div>
                    </div>
                    <div className="other-contact">
                        <div className="mars-style">
                            <div className="contact-container noto-san">
                                <div className="contact-title noto-san">
                                    <h3>札幌本社</h3>
                                </div>
                                <div className="contact-details">
                                    <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                                    <p className="details">TEL：011-231-6033</p>
                                    <div className="map-icon">
                                        <p className="map">Google map</p>
                                        <div className="icon">
                                            <img src={CircleArrow} alt="arrow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-container">
                                <div className="contact-title noto-san">
                                    <h3>札幌本社</h3>
                                </div>
                                <div className="contact-details noto-san">
                                    <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                                    <p className="details">TEL：011-231-6033</p>
                                    <div className="map-icon">
                                        <p className="map">Google map</p>
                                        <div className="icon">
                                            <img src={CircleArrow} alt="arrow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-top">
                <button
                    className="scroll-to-top-button noto-san"
                    onClick={scrollToTop}
                >
                    <div className="image-container">
                        <img src={ArrowUp} />
                    </div>
                    <p>PAGE TOP</p>
                </button>
            </div>
            <Footer />
        </div>
    );
};

export default Partner;