import React, { useState } from 'react';
import '../scss/cookies.scss';

const CookiesModal = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    
  };

  const handleCancel = () => {
    setAccepted(true);
  };

  if (accepted) {
    return null; 
  }

  return (
    <div className="cookies-modal">
      <div className="cookies-content">
        <p>当サイトではサービス向上、営業・マーケティングの目的からCookieを使用します。Cookieの利用に同意頂ける場合は、「同意する」ボタンを押してください</p>
        <div className="buttons">
          <button onClick={handleAccept}>同意する</button>
          <button onClick={handleCancel}>拒否する</button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;