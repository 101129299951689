import React from 'react'
import "../scss/textbox.scss"

export default function TextBox({text}) {
  return (
    <div className='text-box'>
        <h3 className='text noto-san'>{text}</h3>
    </div>
  )
}
