import React from 'react'
import PropTypes from 'prop-types';
import '../scss/blockcard.scss';
import SimpleButton from './SimpleButton';

BlockCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string
};

export default function BlockCard({ title, image, content }) {

    return (
        <a href="https://nution.persol-career.co.jp/" className='block-card'>
            <div className='image-container'>
                {image && <img src={image} alt={title} className="card-image" />}
            </div>
            <div className='container noto-san'>
                <h3 className='title '>{title}</h3>
                <p dangerouslySetInnerHTML={{ __html: content }} className='content'></p>
            </div>
            <div className='button-container'>
                <SimpleButton
                label="詳しく見る"
                />
            </div>
        </a>
    )
}
