import React from 'react'
import Header from '../components/layout/header/Header'
import Footer from '../components/layout/header/Footer'
import ArrowUp from "../images/pagetop/arrowup.png"
import MessageIcon from "../images/section8/message.png"
import PhoneIcon from "../images/section8/phone.png"
import CircleArrow from "../images/section8/circlearrow.png"
import "../scss/corporate.scss"
// import "../scss/common.scss"

export default function Corporate() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <div className='corporate'>
            <Header />
            <div className='section-legend'>
                <p>アリスタイルTOP &gt;</p>
                
            </div>

            <div className='section1'>
                <div className='background'></div>
                <div className='mars-style'>
                <div className='title noto-san'>
                    <span>Company</span>
                    <h3>企業情報</h3>
                </div>
                </div>
            </div>

            <div className='section2'>
                <div className='mars-style'>
                    <div className='first-part'>
                        <span>     </span>
                        <div className='title'>
                            <h3>経営理念</h3>
                        </div>
                        <div className='details'>
                            <p>全従業員の物心両面の幸福を追求し</p>
                            <div className='list'>
                                <p>一．私たちの技術、知識、知恵を通じてお客様の成長発展に貢献します。</p>
                                <p>一．世のため人のためになる商品・サービスを提供します。</p>
                                <p>一．企業価値を高め、地域・社会の進歩発展に貢献します。</p>
                            </div>
                        </div>
                    </div>
                    <div className='second-part'>
                        <span>    </span>
                        <div className='title'>
                            <h3>会社概要</h3>
                        </div>
                        <div className='list-container'>
                            <div className='list'>
                                <span className='left-list'>社名</span> <div className='right-list'><p>株式会社アリスタイル</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>所在地</span> <div className='right-list'>
                                    <p className='bold'>札幌本社</p>
                                    <p>〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階（Google MAP）</p>
                                    <p>Tel：011-231-6033 / Fax：011-211-0702</p>
                                    <p className='bolds'>東京営業所</p>
                                    <p>〒105-0004 東京都港区新橋2-16-1 ニュー新橋ビル1113号室（Google MAP）</p>
                                    <p>Tel：03-3539-2777</p>

                                    </div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>資本金</span> <div className='right-list'><p>2010年3月25日</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>代表者</span> <div className='right-list'><p>3,000,000円</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>従業員数</span> <div className='right-list'><p>11月末日</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>事業内容</span> <div className='right-list'><p>代表取締役 吉田 寛</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>認証・資格</span> <div className='right-list'><p>19名（2022年3月20日現在）</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>加盟団体</span> <div className='right-list'><p>DXコンサルティング／システム開発／Webマーケティング／SaaS導入支援／MAStyleの提供／グラフィックデザイン</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>取引先</span> 
                                <div className='right-list'>
                                    <p>ISO/IEC 27001:2013（JIS Q 27001:2014）</p>
                                    <p>電気通信事業者（J-28-590）</p>
                                </div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>加盟団体</span> <div className='right-list'><p>札幌商工会議所</p></div>
                            </div>
                            <div className='list'>
                                <span className='left-list'>取引先</span> <div className='right-list'><p>電通アイソバー株式会社／株式会社電通デジタル／株式会社電通東日本／株式会社電通北海道／株式会社博報堂プロダクツ／株式会社北海道博報堂 （敬称略・五十音順）</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section8">
                <div className="container">
                    <div className="contact noto-san">
                        <span>Contact</span>
                        <h3>DXに関するご相談、制作のお見積り依頼はこちらから</h3>
                    </div>
                    <div className="icon-container">
                        <div className="message-icon">
                            <div className="icon-container noto-san">
                                <img src={MessageIcon} />
                            </div>
                            <p>メールでのお問い合わせ</p>
                        </div>
                        <div className="phone-icon">
                            <div className="icon">
                                <img src={PhoneIcon} />
                            </div>
                            <div className="details">
                                <p className="title">お電話でのお問い合わせ</p>
                                <p className="number">011-231-6033</p>
                                <p className="time">9:00~18:00（土日祝休み）</p>
                            </div>
                        </div>
                    </div>
                    <div className="other-contact">
                        <div className="mars-style">
                            <div className="contact-container noto-san">
                                <div className="contact-title noto-san">
                                    <h3>札幌本社</h3>
                                </div>
                                <div className="contact-details">
                                    <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                                    <p className="details">TEL：011-231-6033</p>
                                    <div className="map-icon">
                                        <p className="map">Google map</p>
                                        <div className="icon">
                                            <img src={CircleArrow} alt="arrow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-container">
                                <div className="contact-title noto-san">
                                    <h3>札幌本社</h3>
                                </div>
                                <div className="contact-details noto-san">
                                    <p className="details">〒060-0002 北海道札幌市中央区北2条西2丁目26番 道特会館7階</p>
                                    <p className="details">TEL：011-231-6033</p>
                                    <div className="map-icon">
                                        <p className="map">Google map</p>
                                        <div className="icon">
                                            <img src={CircleArrow} alt="arrow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 
            <div className="page-top">
                <button
                    className="scroll-to-top-button noto-san"
                    onClick={scrollToTop}
                >
                    <div className="image-container">
                        <img src={ArrowUp} />
                    </div>
                    <p>PAGE TOP</p>
                </button>
            </div>
            <Footer />
        </div>
    )
}
