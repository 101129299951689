import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import LandingPage from "./assets/page/LandingPage";
import Corporate from "./assets/page/Corporate";
import Partner from "./assets/page/Partner";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/partner" element={<Partner />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
