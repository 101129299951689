import React, {useState} from 'react';
import Ads from '../images/ads/ads.png';
import Xmark from '../images/ads/xmark.png'
import "../scss/adscomponent.scss"

export default function AdsComponent() {

    const [accepted, setAccepted] = useState(false);

    const handleAccept = () => {
      setAccepted(true);
      
    };

    if (accepted) {
        return null; 
      }
  return (
    <div className='ads-container'>
        <div className='ads'>
            <img src={Ads} />
        </div>
        <div className='close' onClick={handleAccept}>
            <img src={Xmark} />
        </div>
    </div>
  )
}
